import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {MobileMonthlyEvent} from './event'
import s from './mobile-monthly-day.scss'
import {MobileMonthlyDayProps} from '.'

export const MobileMonthlyDay = ({
  borderStyleRight,
  borderStyleBottom,
  events,
  past,
  today,
  dayNumber,
  currentDate,
}: MobileMonthlyDayProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  return (
    <div className={s.content} data-hook={`calendar-day-${dayNumber}`}>
      <div
        className={classNames(s.day, {
          [s.past]: past,
          [s.today]: today,
          [s.mobileFontSize]: mobileFontOverridesEnabled,
        })}
        style={borderStyleRight}
      >
        <div className={s.dayText} data-hook={`day-of-month-${dayNumber}`}>
          {dayNumber}
        </div>
      </div>
      <ul className={s.events}>
        {events.map(event => (
          <li key={event.id} className={s.event} style={borderStyleBottom}>
            <MobileMonthlyEvent event={event} currentDate={currentDate} />
          </li>
        ))}
      </ul>
    </div>
  )
}
