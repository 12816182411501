import {WithTranslation, withTranslation} from '@wix/yoshi-flow-editor'
import {
  getDaysInGridMonth,
  getReferencedMonth,
  getWeekdaysFromStartWeekday,
} from '../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../utils/calendar'
import {AppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {MonthlyCalendarGrid as CalendarGridPresentation} from './monthly-calendar-grid'

export interface MonthlyCalendarGridOwnProps {}

export interface MonthlyCalendarGridRuntimeProps {
  shownDays: string[]
  weekdayNames: string[]
  borderStyle: React.CSSProperties
}

export interface MonthlyCalendarGridProps
  extends MonthlyCalendarGridRuntimeProps,
    MonthlyCalendarGridOwnProps,
    WithTranslation {}

export interface MonthlyCalendarCellRefs {
  [week: number]: MonthlyCalendarWeekCellRefs
}

export interface MonthlyCalendarWeekCellRefs {
  [weekDay: number]: HTMLElement
}

export interface MonthlyCalendarCellSizes {
  [week: number]: MonthlyCalendarWeekCellSizes
}

export interface MonthlyCalendarWeekCellSizes {
  [weekDay: number]: MonthlyCalendarCellSize
}

export interface MonthlyCalendarCellSize {
  width: number
  height: number
}

const mapRuntime = (context: AppProps): MonthlyCalendarGridRuntimeProps => ({
  shownDays: getDaysInGridMonth(context.state, getReferencedMonth(context.state)),
  weekdayNames: getWeekdaysFromStartWeekday(context.state),
  borderStyle: getBorderStyle(context),
})

export const MonthlyCalendarGrid = connect<MonthlyCalendarGridOwnProps, MonthlyCalendarGridRuntimeProps>(mapRuntime)(
  withTranslation()(CalendarGridPresentation),
)
