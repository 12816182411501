import {WithTranslation, withTranslation} from '@wix/yoshi-flow-editor'
import type {
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
} from '../../../../../actions/calendar-layout'
import {
  getDayNumber,
  getEventsForDay,
  getSelectedDate,
  getToday,
  isDayInCurrentMonth,
} from '../../../../../selectors/calendar-layout'
import {getActionBackground, getBorderStyle, getOtherMonthBackground} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {CalendarCell as CalendarCellPresentation} from './calendar-cell'

export interface CalendarCellOwnProps extends WithTranslation {
  timestamp: string
  week: number
  weekDay: number
  registerContainerRef: (week: number, weekDay: number, ref: HTMLElement) => void
}

export interface CalendarCellState {
  hover: boolean
}

export interface CalendarCellRuntimeProps {
  events: wix.events.Event[]
  isPast: boolean
  isToday: boolean
  isCurrentMonth: boolean
  dayNumber: number
  selectionExists: boolean
  active: boolean
  borderStyle: React.CSSProperties
  otherMonthBackground: React.CSSProperties
  actionBackground: React.CSSProperties
  openMonthlyCalendarPopup: typeof openMonthlyCalendarPopup
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
}

export interface CalendarCellProps extends CalendarCellRuntimeProps, CalendarCellOwnProps {}

const mapRuntime = (context: AppProps, {timestamp}: CalendarCellOwnProps): CalendarCellRuntimeProps => ({
  events: getEventsForDay(context.state, timestamp),
  isPast: timestamp < getToday(context.state),
  isToday: timestamp === getToday(context.state),
  isCurrentMonth: isDayInCurrentMonth(context.state, timestamp),
  dayNumber: getDayNumber(context.state, timestamp),
  selectionExists: Boolean(getSelectedDate(context.state)),
  active: timestamp === getSelectedDate(context.state),
  borderStyle: getBorderStyle(context),
  otherMonthBackground: getOtherMonthBackground(context),
  actionBackground: getActionBackground(context),
  openMonthlyCalendarPopup: context.actions.openMonthlyCalendarPopup,
  openMonthlyCalendarEvent: context.actions.openMonthlyCalendarEvent,
  closeMonthlyCalendarPopup: context.actions.closeMonthlyCalendarPopup,
})

export const CalendarCell = withTranslation()(
  connect<CalendarCellOwnProps, CalendarCellRuntimeProps>(mapRuntime)(CalendarCellPresentation),
)
