import {withTranslation} from '@wix/yoshi-flow-editor'
import {getDaysInMonthWithEvents, getReferencedMonth} from '../../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {MobileMonthlyDaysOwnProps, MobileMonthlyDaysRuntimeProps} from './interfaces'
import {MobileMonthlyDays as MobileMonthlyDaysPresentation} from './mobile-monthly-days'

const mapRuntime = (context: AppProps): MobileMonthlyDaysRuntimeProps => ({
  days: getDaysInMonthWithEvents(context.state, getReferencedMonth(context.state)),
  borderStyleTop: getBorderStyle(context, 'top'),
  borderStyleBottom: getBorderStyle(context, 'bottom'),
})

export const MobileMonthlyDays = connect<MobileMonthlyDaysOwnProps, MobileMonthlyDaysRuntimeProps>(mapRuntime)(
  withTranslation()(MobileMonthlyDaysPresentation),
)
export * from './interfaces'
