import {getDayNumber, getEventsForDay, getToday} from '../../../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../../../utils/calendar'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MobileMonthlyDay as MobileMonthlyDayPresentation} from './mobile-monthly-day'

export interface MobileMonthlyDayOwnProps {
  day: string
}

export interface MobileMonthlyDayRuntimeProps {
  events: wix.events.Event[]
  past: boolean
  today: boolean
  dayNumber: number
  currentDate: string
  borderStyleRight: React.CSSProperties
  borderStyleBottom: React.CSSProperties
}

export interface MobileMonthlyDayProps extends MobileMonthlyDayRuntimeProps, MobileMonthlyDayOwnProps {}

const mapRuntime = (context: AppProps, {day}: MobileMonthlyDayOwnProps): MobileMonthlyDayRuntimeProps => ({
  events: getEventsForDay(context.state, day),
  past: day < getToday(context.state),
  today: day === getToday(context.state),
  dayNumber: getDayNumber(context.state, day),
  currentDate: day,
  borderStyleRight: getBorderStyle(context, 'right'),
  borderStyleBottom: getBorderStyle(context, 'bottom'),
})

export const MobileMonthlyDay = connect<MobileMonthlyDayOwnProps, MobileMonthlyDayRuntimeProps>(mapRuntime)(
  MobileMonthlyDayPresentation,
)
