import {TFunction} from '@wix/yoshi-flow-editor'
import type {closeMonthlyCalendarPopup, openMonthlyCalendarEvent} from '../../../../../../actions/calendar-layout'
import {getDayMonthText, getEventsForDay, getSelectedDate} from '../../../../../../selectors/calendar-layout'
import {State} from '../../../../../../types/state'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MonthlyCalendarEventList as MonthlyCalendarEventListPresentation} from './monthly-calendar-event-list'

export interface MonthlyCalendarEventListOwnProps {
  t: TFunction
}

export interface MonthlyCalendarEventListRuntimeProps {
  state: State
  dateText: string
  events: ExtendedEvent[]
  selectedDate: string
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
}

export interface MonthlyCalendarEventListProps
  extends MonthlyCalendarEventListRuntimeProps,
    MonthlyCalendarEventListOwnProps {}

const mapRuntime = (context: AppProps): MonthlyCalendarEventListRuntimeProps => {
  const selectedDate = getSelectedDate(context.state)

  return {
    state: context.state,
    events: getEventsForDay(context.state, selectedDate),
    dateText: getDayMonthText(context.state, selectedDate),
    selectedDate,
    openMonthlyCalendarEvent: (eventId: string, selectedTimestamp: string) =>
      context.actions.openMonthlyCalendarEvent(eventId, selectedTimestamp),
    closeMonthlyCalendarPopup: () => context.actions.closeMonthlyCalendarPopup(),
  }
}

export const MonthlyCalendarEventList = connect<MonthlyCalendarEventListOwnProps, MonthlyCalendarEventListRuntimeProps>(
  mapRuntime,
)(MonthlyCalendarEventListPresentation)
