import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import {ChevronUp} from '@wix/wix-ui-icons-common'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../../../../../../commons/hooks/use-mobile-font-overrides'
import {getMultiDayText} from '../../../../../../../../../../../commons/selectors/event'
import {EventImage} from '../../../../../../../event-image'
import {LinkToPage} from '../../../../../../../link-to-page'
import {Members} from '../../../../../../../members'
import {Ribbon} from '../../../../../../../ribbon'
import {RsvpButton} from '../../../../../../../rsvp-button'
import {SocialBar} from '../../../../../../../social-bar'
import s from './mobile-monthly-event-details.scss'
import {MobileMonthlyEventDetailsProps} from '.'

export const MobileMonthlyEventDetails = ({
  event,
  hasImage,
  showMembers,
  date,
  multiDay,
  showRibbon,
  closeMonthlyCalendarEvent,
  state,
  selectedDate,
}: MobileMonthlyEventDetailsProps) => {
  const {t} = useTranslation()
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  const multiDayText = getMultiDayText(state, event.id, selectedDate, t)

  const handleEventClick = () => {
    closeMonthlyCalendarEvent()
  }

  return (
    <div className={classNames(s.container, {[s.mobileFontSize]: mobileFontOverridesEnabled})}>
      <div className={s.eventInfo} onClick={handleEventClick} data-hook={`event-details-${event.id}`}>
        {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
        {multiDay ? (
          <div className={s.time}>{multiDayText}</div>
        ) : (
          <div className={s.fullDate} data-hook="date">
            {date}
          </div>
        )}
        <div
          className={classNames({
            [s.multiDayTitle]: multiDay,
            [s.title]: !multiDay,
            [s.mobileFontSize]: mobileFontOverridesEnabled,
          })}
          data-hook="title"
        >
          <LinkToPage event={event}>{event.title}</LinkToPage>
          <div className={s.chevron}>
            <ChevronUp size="1.2em" />
          </div>
        </div>
      </div>
      {hasImage ? (
        <div className={s.image}>
          <EventImage event={event} />
        </div>
      ) : null}
      {multiDay ? (
        <div className={s.fullDate} data-hook="date">
          {date}
        </div>
      ) : null}
      <div className={s.location} data-hook="location">
        {getFormattedFullLocation(event)}
      </div>
      {showMembers ? (
        <div className={s.members} data-hook="members">
          <Members event={event} />
        </div>
      ) : null}
      {event.description ? (
        <div className={s.description} data-hook="description">
          {event.description}
        </div>
      ) : null}
      <div className={s.share} data-hook="share">
        <div className={s.shareText}>{t('shareEventText')}</div>
        <SocialBar t={t} spacing={7} event={event} iconFontSize={14} newColors={false} row hideTitle />
      </div>
      <RsvpButton
        event={event}
        dataHook="rsvp-button"
        minWidth={88}
        maxWidth={170}
        minHeight={32}
        additionalStyle={{fontSize: 14}}
        containerClassName={s.button}
      />
    </div>
  )
}
