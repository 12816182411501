export {Triangle} from './triangle'

export interface TriangleProps {
  direction: TriangleDirection
}

export enum TriangleDirection {
  left = 'left',
  right = 'right',
}
