import {getEventDateInformation, isMultiDay} from '../../../../../../../../../../../commons/selectors/event'
import {getSelectedDate} from '../../../../../../../../selectors/calendar-layout'
import {isMembersVisible, isRibbonVisible} from '../../../../../../../../selectors/settings'
import {AppProps} from '../../../../../../../app/interfaces'
import {connect} from '../../../../../../../runtime-context/connect'
import {MobileMonthlyEventDetailsOwnProps, MobileMonthlyEventDetailsRuntimeProps} from './interfaces'
import {MobileMonthlyEventDetails as MobileMonthlyEventDetailsPresentation} from './mobile-monthly-event-details'

const mapRuntime = (
  {state, actions}: AppProps,
  {event}: MobileMonthlyEventDetailsOwnProps,
): MobileMonthlyEventDetailsRuntimeProps => {
  return {
    state,
    selectedDate: getSelectedDate(state),
    hasImage: Boolean(event.mainImage),
    showMembers: isMembersVisible(state, event),
    date: getEventDateInformation(state, event.id).fullDate,
    multiDay: isMultiDay(state, event),
    closeMonthlyCalendarEvent: actions.closeMonthlyCalendarEvent,
    showRibbon: isRibbonVisible(state, event),
  }
}

export const MobileMonthlyEventDetails = connect<
  MobileMonthlyEventDetailsOwnProps,
  MobileMonthlyEventDetailsRuntimeProps
>(mapRuntime)(MobileMonthlyEventDetailsPresentation)
export * from './interfaces'
