import classNames from 'classnames'
import React from 'react'
import s from './day-events.scss'
import {EventInfoProps} from '.'

export const DayEvents = ({events, multiDay, firstEventTime, multiDayText, t}: EventInfoProps) => {
  if (!events.length) {
    return null
  }

  return (
    <div className={s.info}>
      <div className={s.fullEvents}>
        <div className={s.time} data-hook="cell-event-time">
          {multiDay ? multiDayText : firstEventTime}
        </div>
        <div className={s.title}>
          <div className={s.titleContent} data-hook="cell-event-title">
            {events[0].title}
          </div>
        </div>
        {events.length > 1 ? (
          <div className={s.more} data-hook="more-link">
            {t('layout.calendar.monthly.events.moreLink', {amount: events.length - 1})}
          </div>
        ) : null}
      </div>
      <div className={classNames(s.compactEvents, s.more)} data-hook="more-link">
        {t('layout.calendar.monthly.events.moreLink', {amount: events.length})}
      </div>
    </div>
  )
}
