import {TFunction, WithTranslation, withTranslation} from '@wix/yoshi-flow-editor'
import {getEventDateInformation, getMultiDayText, isMultiDay} from '../../../../../../../../../commons/selectors/event'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {DayEvents as DayEventsPresentation} from './day-events'

export interface EventInfoOwnProps extends WithTranslation {
  t: TFunction
  events: wix.events.Event[]
  currentDate: string
}

export interface EventInfoRuntimeProps {
  firstEventTime: string
  multiDay: boolean
  multiDayText: string
}

export interface EventInfoProps extends EventInfoRuntimeProps, EventInfoOwnProps {}

const mapRuntime = (context: AppProps, {t, events, currentDate}: EventInfoOwnProps): EventInfoRuntimeProps => {
  const firstEvent = events[0]

  return {
    firstEventTime: getEventDateInformation(context.state, events[0].id).startTime,
    multiDay: isMultiDay(context.state, firstEvent),
    multiDayText: getMultiDayText(context.state, firstEvent.id, currentDate, t),
  }
}

export const DayEvents = withTranslation()(
  connect<EventInfoOwnProps, EventInfoRuntimeProps>(mapRuntime)(DayEventsPresentation),
)
