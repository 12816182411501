import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../../../commons/hooks/use-mobile-font-overrides'
import sc from '../../../../classes.scss'
import {MobileMonthlyDay} from './day'
import s from './mobile-monthly-days.scss'
import {MobileMonthlyDaysProps} from '.'

export const MobileMonthlyDays: React.FC<MobileMonthlyDaysProps> = ({
  days,
  borderStyleBottom,
  borderStyleTop,
  t,
}: MobileMonthlyDaysProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <div className={s.days} style={borderStyleTop}>
      {days.length ? (
        days.map(day => (
          <div key={day} className={s.day} style={borderStyleBottom}>
            <MobileMonthlyDay day={day} />
          </div>
        ))
      ) : (
        <div
          className={classNames(s.empty, sc.textLineHeight, {[s.mobileFontSize]: mobileFontOverridesEnabled})}
          data-hook="calendar-empty"
        >
          {t('layout.calendar.monthly.empty')}
        </div>
      )}
    </div>
  )
}
