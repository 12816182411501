import React from 'react'
import {PopupHeader} from '../header'
import {MonthlyCalendarEventListItem} from './event-list-item'
import s from './monthly-calendar-event-list.scss'
import {MonthlyCalendarEventListProps} from '.'

export class MonthlyCalendarEventList extends React.PureComponent<MonthlyCalendarEventListProps> {
  handleKeyPress = (domEvent, eventId: string, selectedDate: string) => {
    if (domEvent.key === 'Enter') {
      this.props.openMonthlyCalendarEvent(eventId, selectedDate)
    }
  }

  render() {
    const {state, dateText, closeMonthlyCalendarPopup, selectedDate, t, events, openMonthlyCalendarEvent} = this.props

    return (
      <div className={s.container} data-hook="calendar-event-list">
        <PopupHeader eventList text={dateText} onClose={closeMonthlyCalendarPopup} t={t} />
        <ul className={s.list}>
          {events.map(event => (
            <MonthlyCalendarEventListItem
              t={t}
              state={state}
              key={event.id}
              onClick={() => openMonthlyCalendarEvent(event.id, selectedDate)}
              onKeyPress={domEvent => this.handleKeyPress(domEvent, event.id, selectedDate)}
              event={event}
              selectedDate={selectedDate}
            />
          ))}
        </ul>
      </div>
    )
  }
}
